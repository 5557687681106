import axios from 'axios'

// response time
axios.defaults.timeout = 20000
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.baseURL = '/'; //Base URL

// axios.interceptors.request.use((config) => {
//   //before send
//   if (config.method === 'post') {
//     config.data = qs.stringify(config.data, {
//       arrayFormat: 'repeat'
//     });
//   }
//   return config;
// }, (error) => {
//   this.$message.error('Oops, this is a error.');
//   return Promise.reject(error);
// });

axios.interceptors.response.use(
  (res) => {
    if (res.status != 200) {
      // _.toast(res.data.msg);
      return Promise.reject(res)
      // var exception = new CommonException('HTTP Error', 1);
      // throw exception;
    }
    return res
  },
  (error) => {
    // console.log(error)
    if (error.response) {
      // Promise.reject(error)
      // if (error.response.status == 401) {
      //   this.$message.error('Oops, this is a error.')
      //   return
      // }
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.log('Error', error.message)
      // return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)
//return Promise
export function fetch(url, params, headers) {
  return fetchPost(url, params, headers)
}

export function fetchPost(url, datas, params, headers) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, datas, {
        params,
        headers
      })
      .then((response) => {
        if (response) return resolve(response.data.result)
        else return reject(response)
      })
      .catch((error) => {
        return reject(error)
      })
  })
}
export function fetchGet(url, params, headers) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params,
        headers
      })
      .then(
        (response) => {
          if (response && response.status) {
            if (response.data.options) {
              // Support pageing
              resolve({
                result: response.data.result,
                options: response.data.options
              })
            } else {
              resolve(response.data.result)
            }
          } else {
            reject(new Error('Connection server error'))
          }
        },
        (err) => {
          reject(err)
        }
      )
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export function defaultCatch(error) {
  console.error(error)
  return Promise.reject(error)
}
export function defaultSuccess(assessment) {
  return Promise.resolve(assessment)
}
